// eslint-disable-next-line import/prefer-default-export
export const EVENTS = {
  updateList: 'update-campaigns-list',
  create: 'create-campaigns',
  update: 'update-campaigns',
};

// Campaign statuses
export const CAMPAIGN_UPCOMING = 1;
export const CAMPAIGN_RUNNING = 2;
export const CAMPAIGN_COMPLETED = 3;
export const CAMPAIGN_STATUS_NAMES = {
  [CAMPAIGN_UPCOMING]: 'Upcoming',
  [CAMPAIGN_RUNNING]: 'Running',
  [CAMPAIGN_COMPLETED]: 'Completed',
};

// Campaigns type tabs
export const CAMPAIGN_TYPE_TAB_PUBLIC = 0;
export const CAMPAIGN_TYPE_TAB_PRIVATE = 1;
export const CAMPAIGNS_TYPE_TAB_OPTIONS = [
  {
    label: 'Public',
    value: CAMPAIGN_TYPE_TAB_PUBLIC,
  },
  {
    label: 'Private',
    value: CAMPAIGN_TYPE_TAB_PRIVATE,
  },
];

// Private campaigns
export const PREVENT_MANAGE_PRIVATE_CAMPAIGNS_MESSAGE =
  'Private content cannot be in multiple campaigns';
export const BELONGS_PRIVATE_CAMPAIGN_TOOLTIP = 'belongs to a private campaign';
